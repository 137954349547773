import ApiRequestError from '../utils/errors/ApiRequestError';
import ResponseFailedError from '../utils/errors/ResponseFailedError';
import { Getters as ApiGetters } from './apiStore';
import { BASE_PATH } from "../../libs/dist/bandaapp-openapi/base"
const Mutations = {
  setEquipment: "setEquipment",
}

export const Actions = {
  retrieveEquipmentList: "retrieveEquipmentList",
  retrieveEquipment: "retrieveEquipment",
  deleteEquipment: "deleteEquipment",
  createEquipment: "createEquipment",
  updateEquipment: "updateEquipment",
  bulkUnassign: "bulkUnassign",
  printQrCodes: "printQrCodes",
};

// Create a new store instance.
const equipmentModule = {
  state() {
    return {
      equipment: []
    }
  },
  mutations: {
    [Mutations.setEquipment](state, equipment) {
      state.equipment = equipment;
    },
  },
  actions: {
    async [Actions.retrieveEquipmentList](context, filters = {}) {
      try {
        const axiosResponse = await context.getters[ApiGetters.equipmentController].apiEquipmentGet(
          filters.searchField,
          filters.searchString,
          filters.pageSize,
          filters.pageNumber,
          filters.sortBy,
          filters.sortOrder,
          filters.isAssignedToUser,
          filters.assignedOnly,
          filters.userSearch,
          filters.search,
        );

        const response = axiosResponse.data;
        if (response.status == "success") {
          const equipment = response.data;

          context.commit(Mutations.setEquipment, equipment);

          return equipment;
        } else {
          throw new ResponseFailedError(response.message, response.data);
        }
      } catch (e) {
        if (e instanceof ResponseFailedError) {
          throw e;
        } else {
          throw new ApiRequestError(e);
        }
      }
    },
    async [Actions.retrieveEquipment](context, id) {
      try {
        const axiosResponse = await context.getters[ApiGetters.equipmentController].apiEquipmentIdGet(id);

        const response = axiosResponse.data;
        if (response.status == "success") {
          const equipment = response.data;

          return equipment;
        } else {
          throw new ResponseFailedError(response.message, response.data);
        }
      } catch (e) {
        if (e instanceof ResponseFailedError) {
          throw e;
        } else {
          throw new ApiRequestError(e);
        }
      }
    },
    async [Actions.deleteEquipment](context, id) {
      try {
        console.log(context.getters[ApiGetters.equipmentController]);
        const axiosResponse = await context.getters[ApiGetters.equipmentController].apiEquipmentIdDelete(id);

        const response = axiosResponse.data;
        if (response.status == "success") {
          const Equipment = response.data;

          return Equipment;
        } else {
          throw new ResponseFailedError(response.message, response.data);
        }
      } catch (e) {
        if (e instanceof ResponseFailedError) {
          throw e;
        } else {
          throw new ApiRequestError(e);
        }
      }
    },
    async [Actions.bulkUnassign](context, form) {
      try {
        const axiosResponse = await context.getters[ApiGetters.equipmentController].apiEquipmentBulkUnassignPost(form);

        const response = axiosResponse.data;
        if (response.status == "success") {
          const Equipment = response.data;

          return Equipment;
        } else {
          throw new ResponseFailedError(response.message, response.data);
        }
      } catch (e) {
        if (e instanceof ResponseFailedError) {
          throw e;
        } else {
          throw new ApiRequestError(e);
        }
      }
    },
    async [Actions.createEquipment](context, equipment) {
      try {
        const axiosResponse = await context.getters[ApiGetters.equipmentController].apiEquipmentPost(equipment);

        const response = axiosResponse.data;
        if (response.status == "success") {
          return response.data;
        } else {
          throw new ResponseFailedError(response.message, response.data);
        }
      } catch (e) {
        if (e instanceof ResponseFailedError) {
          throw e;
        } else {
          throw new ApiRequestError(e);
        }
      }
    },
    async [Actions.updateEquipment](context, equipment) {
      try {
        const axiosResponse = await context.getters[ApiGetters.equipmentController].apiEquipmentIdPut(equipment.id, equipment);

        const response = axiosResponse.data;
        if (response.status == "success") {
          const Equipment = response.data;

          return Equipment;
        } else {
          throw new ResponseFailedError(response.message, response.data);
        }
      } catch (e) {
        if (e instanceof ResponseFailedError) {
          throw e;
        } else {
          throw new ApiRequestError(e);
        }
      }
    },
    async [Actions.printQrCodes](context, qrCodes) {
      try {
        window.open(BASE_PATH + "/api/equipment/printQrCodes?qrCodes=" + qrCodes, "_blank");
      } catch (e) {
        if (e instanceof ResponseFailedError) {
          throw e;
        } else {
          throw new ApiRequestError(e);
        }
      }
    },
  }
};

export default equipmentModule;