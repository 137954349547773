import ApiRequestError from '../utils/errors/ApiRequestError';
import { Getters as ApiGetters } from './apiStore';

export const Actions = {
  generateEventUsers: "generateEventUsers",
  generateEvents: "generateEvents",
  generateUsers: "generateUsers",
};

// Create a new store instance.
const excelModule = {
  actions: {
    async [Actions.generateEventUsers](context, filters = {}) {
      try {
        const axiosResponse = await context.getters[ApiGetters.excelController].apiExcelsEventUserGet(
          filters.searchField,
          filters.searchString,
          filters.attendanceStatusFilter,
          { responseType: "blob" }
        );
        
        const response = axiosResponse.data;

        const mimeType = axiosResponse.headers["content-type"];
        
        return new File([response], "Event Users.xlsx", { type: mimeType });
      } catch (e) {
        throw new ApiRequestError(e);
      }
    },
    async [Actions.generateEvents](context, filters = {}) {
      try {
        const axiosResponse = await context.getters[ApiGetters.excelController].apiExcelsEventGet(
          filters.searchField,
          filters.searchString,
          { responseType: "blob" }
        );
        
        const response = axiosResponse.data;

        const mimeType = axiosResponse.headers["content-type"];
        
        return new File([response], "Events.xlsx", { type: mimeType });
      } catch (e) {
        throw new ApiRequestError(e);
      }
    },
    async [Actions.generateUsers](context, filters = {}) {
      try {
        const axiosResponse = await context.getters[ApiGetters.excelController].apiExcelsUserGet(
          filters.searchField,
          filters.searchString,
          { responseType: "blob" }//options
        );
        
        const response = axiosResponse.data;

        const mimeType = axiosResponse.headers["content-type"];
        
        return new File([response], "Users.xlsx", { type: mimeType });
      } catch (e) {
        throw new ApiRequestError(e);
      }
    },
  }
};

export default excelModule;